.pdfDocumentViewer {
  position: relative;
  padding: var(--inner-spacing-4);
}

.spinner {
  position: absolute;
  top: var(--inner-spacing-4);
  left: 50%;
  transform: translateX(-50%);
}

.citationHighlight {
  position: absolute;
  background: rgba(250, 244, 100, 0.3);
}
